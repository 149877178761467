import { Button, Col, Form, Input, Row, Space, Modal } from "antd";
import axios from "axios";
import {
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  FacebookOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

export const ContactUs = () => {
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const postSlackMessage = ({ params }) => {
    return axios
      .post(".netlify/functions/postSlackMessageChannel", {
        params,
      })
      .then((response) => {
        console.log(response);
      })
      .catch(function error(error) {
        const errorMessage = error.response.data;
        console.log(errorMessage);
      });
  };

  const onFinish = (values) => {
    form.validateFields().then((values) => {
      const success = () => {
        Modal.success({
          content:
            "Thanks for getting in touch with us... Will get in touch ASAP!!",
        });
      };
      const slackMessageData = {
        //enter slack url here
        params: {
          url: "",
          blocks: [
            {
              type: "header",
              text: {
                type: "plain_text",
                text: "Contact Message from pages.lk!",
                emoji: true,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `Name: ${values.name} \n\n Email: ${values.email} \n\n Contact Number: ${values.number} \n\n Comments: ${values.message}  `,
              },
            },
          ],
        },
      };
      postSlackMessage(slackMessageData);
      success();
      form.resetFields();
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const ContactForm = () => {
    return (
      <Form
        name="basic"
        form={form}
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={18}>
          <Col xs={24} sm={24} md={12} lg={24}>
            <Form.Item
              style={{ margin: "5px" }}
              label=""
              name="name"
              rules={[{ required: true, message: "Please input your name" }]}
            >
              <Input placeholder="name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={24}>
            <Form.Item
              style={{ margin: "5px" }}
              label=""
              name="number"
              rules={[
                {
                  required: true,
                  message: "Please input your contact number!",
                },
              ]}
            >
              <Input placeholder="Contact Number" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          style={{ margin: "5px" }}
          label=""
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input placeholder="email" />
        </Form.Item>
        <Form.Item
          style={{ margin: "5px" }}
          label=""
          name="message"
          rules={[{ required: false, message: "Please input your email!" }]}
        >
          <TextArea
            placeholder="your interested in getting your property listed"
            rows={3}
          />
        </Form.Item>
        <Form.Item style={{ margin: "5px" }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: "black" }}
          >
            Submit
          </Button>
        </Form.Item>
        <p style={{ fontWeight: "700", margin: "5px", marginTop: "15px" }}>
          GET SOCIAL
        </p>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Space>
            <InstagramOutlined />
            <LinkedinOutlined />
            <TwitterOutlined />
            <FacebookOutlined />
            <YoutubeOutlined />
          </Space>
          <br></br>
          <div>
            <p>+94-777-322500 / email@techhq.lk</p>
          </div>
        </Col>
      </Form>
    );
  };

  return <ContactForm />;
};
