import { Divider } from "antd";

import { Hero } from "../components/landingPage/hero";
import { AboutUs } from "../components/landingPage/aboutUs";
import { Examples } from "../components/landingPage/examples";
import { Pricing } from "../components/landingPage/pricing";

import { Footer } from "../components/navigation/footer";
import { DynamicMetaTags } from "../utils/dynamicMetaTags";

export const LandingPage = ({ helloWorld }) => {
  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <DynamicMetaTags
        ogTitle={`3D Capture for Real Estate | 3d.lk`}
        ogImage={
          "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-3d-lk/og-image-3d-lk-01.png"
        }
        ogDescription={
          "Our 3D cameras and virtual tour help you digitize your building, automatically create 3D tours, 4K print quality photos, schematic floor plans, OBJ files, point clouds, videos, and more."
        }
        ogUrl={"3d.lk"}
      />
      <div style={{ maxWidth: "1200px" }}>
        <Hero />
        <Divider />
        <AboutUs />
        <Divider />
        <Examples />
        <Divider />
        <Pricing />
        <Footer />
      </div>
    </div>
  );
};
