import { Col, Row, Divider } from "antd";

export const Details = ({ propertyItem }) => {
  // const listCommericalTerms = propertyItem.commericalTerms.map((data) => {
  //   return (
  //     <li id={data.id}>
  //       <strong>{data.cat}</strong>
  //       {" : "}
  //       {data.text}
  //     </li>
  //   );
  // });

  // const listLettingDetails = propertyItem.lettingDetails.map((data) => {
  //   return (
  //     <li id={data.id}>
  //       <strong>{data.cat}</strong>
  //       {" : "}
  //       {data.text}
  //     </li>
  //   );
  // });

  // const listKeyFeatures = propertyItem.keyFeatures.map((data) => {
  //   return (
  //     <li id={data.id}>
  //       <strong>{data.cat}</strong>
  //       {data.cat && " : "}
  //       {data.text}
  //     </li>
  //   );
  // });
  // const listDocuments = propertyItem.documents.map((data) => {
  //   return (
  //     <li id={data.id}>
  //       <a target={"_blank"} rel={"noreferrer"} href={data.docUrl}>
  //         {data.title}
  //       </a>
  //     </li>
  //   );
  // });

  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Row gutter={[4, 4]} style={{ maxWidth: "1200px", margin: "15px" }}>
        <Col span={24} style={{ textAlign: "center" }}>
          <Divider> Description</Divider>
          {propertyItem.description}
        </Col>
        {/* <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Divider>Commerical Terms</Divider>
          <ul>{listCommericalTerms}</ul>
          <Divider>Letting Details</Divider>
          <ul>{listLettingDetails}</ul>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Divider>details</Divider>
          <Row gutter={[4, 4]}>
            <Col span={8}>
              <Statistic title="Property Type" value={propertyItem.type} />
            </Col>

            {propertyItem.bedrooms && (
              <Col span={8}>
                <Statistic title="Bedrooms" value={propertyItem.bedrooms} />
              </Col>
            )}

            {propertyItem.bathrooms && (
              <Col span={8}>
                <Statistic title={"Bathrooms"} value={propertyItem.bathrooms} />
              </Col>
            )}
            {propertyItem.area && (
              <Col span={8}>
                <Statistic title="Area sqft" value={propertyItem.area} />
              </Col>
            )}
            <Col span={24}>
              <Divider style={{ verticalAlign: "middle" }}>
                <small>documents</small>
              </Divider>
              <Row style={{ textAlign: "left" }}>
                <Col xs={24} sm={24} md={14}>
                  <ul>{listDocuments}</ul>
                </Col>
                <Col xs={24} sm={24} md={10}>
                  <div id="myqrcode" style={{ textAlign: "center" }}>
                    <QRCode value={`https://pages.lk/id/${propertyItem.url}`} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col> */}
        <Divider />
      </Row>
    </div>
  );
};
