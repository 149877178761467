import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";

export const Examples = () => {
  const exampleData = [
    {
      title: "Marine House - Colombo 03",
      url: "/id/marine-house",
      alt: "marine house view from the road",
      src: "https://images.squarespace-cdn.com/content/v1/5e85105860e4d00e03535059/1595262192709-DNMDJRFYXEKFCQGC68M5/Matterport+Dollhouse.gif",
      type: "Office",
      size: "3200+ sqft",
      location: "Kollupitya",
    },
    {
      title: "Crescat - Colombo 03",
      url: "/id/crescat-123c",
      alt: "view of the living room",
      src: "https://www.govirtualtours.co.uk/images/2022/05/18/360-3d-doll-house-view.gif",
      type: "Apartment",
      size: "1 bedroom",
      location: "Crescat",
    },
    {
      title: "Warehouse - Peliyagoda",
      url: "/id/ew-warehouse-1",
      alt: "front the warehouse",
      src: "https://images.squarespace-cdn.com/content/v1/5acfd320c258b4273c51da9e/1628807029981-0PNPY3NL4HF7HSIWM349/IzhWkybtxUpwZ1UeGu.gif",
      type: "Warehouse",
      size: "10,000sqft",
      location: "Peliyagoda",
    },
  ];

  const listExamples = exampleData.map((data) => {
    return (
      <Col xs={24} sm={24} md={8} style={{ textAlign: "center" }}>
        <Link to={data.url}>
          <Card
            style={{ padding: "15px" }}
            hoverable
            cover={<img alt={data.alt} src={data.src} />}
            actions={[data.type, data.size, data.location]}
          >
            {data.title}
          </Card>
        </Link>
      </Col>
    );
  });
  return (
    <div
      style={{
        background: "rgb(225, 225, 219)",
        textAlign: "center",
        backgroundImage: `url(https://img.theculturetrip.com/wp-content/uploads/2017/10/6485862885_4cda9d26c3_b.jpg)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        maxWidth: "1200px",
        width: "100%",
        minHeight: "420px",
        padding: "25px",
      }}
      id="projects"
    >
      <div
        style={{
          color: "white",
          fontWeight: "700",
          fontSize: "1.5rem",
          textAlign: "left",
        }}
      >
        <span style={{ background: "black", padding: "5px" }}>
          VIRTUAL TOURS....
        </span>
      </div>
      <br></br>
      <Row id="examples" gutter={[18, 16]}>
        {listExamples}
      </Row>
    </div>
  );
};
