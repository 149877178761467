import { Card, Col, Row, Space } from "antd";

export const AboutUs = () => {
  return (
    <div
      style={{
        background: "rgb(225, 225, 219)",
        textAlign: "center",
        backgroundImage: `url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkn5tGpLJckS5a6Ag6YEZEhpyn9Yt-FIq7ng&usqp=CAU)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        maxWidth: "1200px",
        width: "100%",
        minHeight: "420px",
        padding: "25px",
      }}
      id="projects"
    >
      <div
        style={{
          color: "white",
          fontWeight: "700",
          fontSize: "1.5rem",
          textAlign: "left",
        }}
      >
        <span style={{ background: "black", padding: "5px" }}>
          WHY GET A 3D TOUR...?
        </span>
      </div>
      <br></br>
      <Row gutter={[18, 16]}>
        <Col xs={24} sm={24} md={8} style={{ textAlign: "center" }}>
          <Card title="Increase" bordered={true}>
            <Row>
              <Col span={24}>
                <div style={{}}>
                  <p style={{ borderStyle: "solid", fontSize: "35px" }}>95%</p>
                </div>
              </Col>
            </Row>
            <ul style={{ listStyle: "none", padding: "0px" }}>
              <p>
                of people are more likely to call about properties with 3D
                virtual tours
              </p>
            </ul>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} style={{ textAlign: "center" }}>
          <Card title="Engage" bordered={true}>
            <Row>
              <Col span={24}>
                <Space>
                  <img
                    width={"70px"}
                    alt="done"
                    src="https://matterport.com/sites/default/files/images/DollhouseView-2x.png"
                  />
                  <img
                    width={"70px"}
                    alt="done"
                    src="https://matterport.com/sites/default/files/images/GuidedTours-2x.png"
                  />{" "}
                  <img
                    width={"70px"}
                    alt="done"
                    src="https://matterport.com/sites/default/files/images/GoogleStreetView-2x.png"
                  />{" "}
                </Space>
              </Col>
            </Row>
            <ul style={{ listStyle: "none", padding: "0px" }}>
              <p>
                Improve viewer engagement by crafting a story about your space.
              </p>
            </ul>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} style={{ textAlign: "center" }}>
          <Card title="Add Value" bordered={true}>
            <Row>
              <Col span={24}>
                <Space>
                  <img
                    alt="example"
                    width={"70px"}
                    src="https://matterport.com/sites/default/files/images/Tags-2x.png"
                  />{" "}
                  <img
                    alt="example"
                    width={"70px"}
                    src="https://matterport.com/sites/default/files/images/Views-2x.png"
                  />{" "}
                  <img
                    alt="example"
                    width={"70px"}
                    src="https://matterport.com/sites/default/files/images/Floorplans-2x.png"
                  />{" "}
                </Space>
              </Col>
            </Row>
            <ul style={{ listStyle: "none", padding: "0px" }}>
              <p>
                Collaborate, plan and share more easily with your team, vendors
                or customers.
              </p>
            </ul>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
