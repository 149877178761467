import { Button, Card, Col, Row } from "antd";
import { useState } from "react";

export const Pricing = () => {
  const [option, setOption] = useState(1);

  const onClick = (selectedOption) => {
    setOption(selectedOption);
  };

  const onClickPurchase = (option) => {
    console.log(option);
  };

  return (
    <div
      style={{
        background: "rgb(225, 225, 219)",
        textAlign: "center",
        backgroundImage: `url(https://media.timeout.com/images/101825237/image.jpg)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        maxWidth: "1200px",
        width: "100%",
        minHeight: "420px",
        padding: "25px",
      }}
      id="projects"
    >
      <div
        style={{
          color: "white",
          fontWeight: "700",
          fontSize: "1.5rem",
          textAlign: "left",
        }}
      >
        <span style={{ background: "black", padding: "5px" }}>
          GET STARTED....
        </span>
      </div>
      <br></br>
      <Row
        gutter={[18, 16]}
        style={{
          padding: "15px",
          justifyContent: "center",
          opacity: "1",
        }}
      >
        <Col xs={24} sm={24} md={8} style={{ textAlign: "center" }}>
          <Card
            className={option === 1 && "selected"}
            title={"Popular with Owners"}
            bordered={true}
          >
            <p style={{ textDecoration: "line-through" }}>{"LKR 100,000"}</p>
            <strong>
              <p>{"LKR 75,000"}</p>
            </strong>
            <ul style={{ listStyle: "none", padding: "0px" }}>
              <li>Approx size 1200sqft</li>
              <li></li>
            </ul>
            <p>
              <small>includes 3months hosting (USD40/-)</small>
            </p>

            {option === 1 ? (
              <Button
                size="large"
                style={{
                  color: "white",
                  backgroundColor: "black",
                  width: "50%",
                }}
                shape="round"
              >
                Selected
              </Button>
            ) : (
              <Button
                onClick={() => onClick(1)}
                shape="round"
                style={{ width: "50%" }}
              >
                Select
              </Button>
            )}
          </Card>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          style={{ textAlign: "center", height: "100%" }}
        >
          <Card
            className={option === 2 && "selected"}
            title={"Renew Hosting"}
            bordered={true}
          >
            <p>{"3 months (USD40/-)"}</p>
            <ul style={{ listStyle: "none", padding: "0px" }}>
              <li>Contact us for mutliple properties</li>
              <li> & Annual Hosting Packages</li>
            </ul>
            <p>
              <small>cancel anytime</small>
            </p>

            {option === 2 ? (
              <Button
                size="large"
                style={{
                  color: "white",
                  backgroundColor: "black",
                  width: "50%",
                }}
                shape="round"
              >
                Selected
              </Button>
            ) : (
              <Button
                onClick={() => onClick(2)}
                shape="round"
                style={{ width: "50%" }}
              >
                Select
              </Button>
            )}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={16} style={{ textAlign: "center" }}>
          <Card bordered={true} hoverable>
            <Button
              onClick={() => {
                onClickPurchase(option);
              }}
              target="_blank"
              rel="noreferrer"
              href={
                option === 1
                  ? "https://buy.stripe.com/6oE4hD6Cq7SKczS28A"
                  : "https://buy.stripe.com/28o29vgd05KC57q7sV"
              }
              size="large"
              style={{
                color: "white",
                backgroundColor: "black",
                width: "100%",
              }}
              shape="round"
            >
              Purchase
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
