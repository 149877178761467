import { Col, Row } from "antd";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Row gutter={[18, 18]} style={{ maxWidth: "1200px" }}>
        <Col span={24}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <p>
              <small>powered by 3d.lk</small>
            </p>
          </Link>
        </Col>
      </Row>
    </div>
  );
};
