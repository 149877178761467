import { Col, Divider, Row, Space } from "antd";
import { ContactUs } from "../landingPage/contactUs";

export const Footer = () => {
  return (
    <div>
      <Row gutter={[18, 18]} style={{ margin: "20px" }}>
        <Divider></Divider>
        <Col xs={24} sm={24} md={9}>
          <p style={{ fontWeight: "900" }}>3d.lk</p>
          <p style={{ fontSize: "14px" }}>
            we create 3d virtual tours of your space, complete with tags ,
            descriptions and label for a more interactive experience.
          </p>
          <Space>
            <a
              style={{
                fontSize: "14px",
                // textDecoration: "none",
                color: "salmon",
              }}
              href={"https://techforgood.lk"}
            >
              {"techForGood.lk"}
              {/* <img
                style={{ height: "15px" }}
                src={
                  "https://jdevs-app.s3.eu-west-2.amazonaws.com/04-slack-artwork/slack-2.svg"
                }
                alt={"slack logo"}
              /> */}
            </a>
          </Space>
          <p>
            Tech For Good (Pvt) is app development company with a social impact
            mission to transmission people into a career in tech!
          </p>
        </Col>{" "}
        <Col xs={0} sm={0} md={5}></Col>
        <Col xs={24} sm={24} md={10}>
          <ContactUs />
        </Col>
        {/* <Col xs={12} sm={12} md={5}>
          <p style={{ fontWeight: "700" }}>Company</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={"https://eastwest.lk"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={12} md={5}>
          <p style={{ fontWeight: "700" }}>Community</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={
                  "https://www.linkedin.com/company/east-west-properties-plc/"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
            </li>
          </ul>
        </Col> */}
        <Divider></Divider>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: "12px" }}>
              {"built by "}
              <a
                href="https://techforgood.lk"
                target={"_blank"}
                rel={"noreferrer"}
                style={{ textDecoration: "none" }}
                color="salmon"
              >
                {"techForGood.lk "}
              </a>
              &copy; {"2023"}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};
