import { Col, Row } from "antd";
import Sticky from "react-stickynode";

export const Title = ({ propertyItem }) => {
  return (
    <Sticky top={47} innerZ={1000}>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Row
          gutter={[4, 4]}
          style={{
            width: "100%",
            padding: "15px",
            backgroundColor: "white",
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <h1 style={{ fontSize: "2em" }}>
              {propertyItem.title}
              {", "}
            </h1>
            <a href="#googleMap">
              <small style={{ fontSize: "12px" }}>
                {propertyItem.location}
              </small>
            </a>
          </Col>
        </Row>
      </div>
    </Sticky>
  );
};
