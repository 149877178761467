import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { NotFound } from "./utils/notFound";
import { NavBar } from "./components/navigation/navBar";
import { LandingPage } from "./pages/LandingPage";
import { PropertyPage } from "./pages/PropertyPage";
import { ListProperties } from "./pages/ListProperties";
import ScrollToTop from "./utils/ScrollToTop";

function App() {
  return (
    <Router>
      <NavBar />
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/all" element={<ListProperties />} />
          <Route exact path="id/:propertyId" element={<PropertyPage />} />
          <Route exact path="/*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
