import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Sticky from "react-stickynode";

import { Link } from "react-router-dom";
import { ContactUsWhatsApp } from "../landingPage/contactUsWhatsApp";

export const NavBar = () => {
  return (
    <Sticky top={0} innerZ={1000}>
      <Navbar>
        <Container>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Navbar.Brand>
              {/* <img
              src={logo}
              className="d-inline-block align-top"
              // width="30"
              height="30"
              alt="logo"
            /> */}
              {"3d.lk"}
            </Navbar.Brand>
          </Link>
          <Nav className="justify-content-end">
            <ContactUsWhatsApp />
            {/* <Button style={{ padding: "5px" }} type="link">
            <Link
              style={{
                color: "salmon",
                textDecoration: "none",
                fontSize: "18px",
              }}
              to="/talent"
            >
              talentDB
              <sup
                style={{
                  margin: "0px 5px 0px",
                  fontSize: "12px",
                  backgroundColor: "white",
                  color: "salmon",
                }}
              >
                pro
              </sup>
            </Link>
          </Button> */}
          </Nav>
        </Container>
      </Navbar>
    </Sticky>
  );
};
